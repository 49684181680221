import { BaseResponse, PageRequest, C6PageRequest, C6BaseResponse } from '@/types/API'
import request, { c6Request, pureC6Request } from '@/utils/request'

/** 获取gps轨迹 */
export function getGpsTrack(orderId: number) {
  return pureC6Request.get<C6BaseResponse>(`/order/ordertrack/getZjxlOrYiliuTrack`, { params: { orderId } })
}

/** 获取小程序轨迹 */
export function getWxTrack(orderId: number) {
  return pureC6Request.get<C6BaseResponse>(`/order/ordertrack/getWxTrack`, { params: { orderId } })
}

/** 获取收发货地址坐标 */
export function getStartAndEndPoint(orderId: number) {
  return pureC6Request.get<C6BaseResponse>(`/order/ordertrack/getStartAndEndPoint`, { params: { orderId } })
}