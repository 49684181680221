
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { defineComponent, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, SpanMethod, StatusConfig, TableColumns } from '@/types/BasicComponent'
import ShippingDetail from '@/views/shipping/ShippingDetail.vue'
import { getOrderList } from '@/api/order'
import { formatTime, formatDate, getAreaListOfGx, getTableColumnsSummary } from '@/utils/common'
import dayjs from 'dayjs'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'

export enum TabPane {
  ALL = 'all',
  UNLOAD = 'unload',
  LOADED = 'loaded',
  DRIVING = 'driving',
  UNCHECK = 'uncheck',
  CHECKED = 'checked',
  BACK = 'back',
}
 
export default defineComponent({
  components: {
    BasicTablePage,
    ShippingDetail,
    BasicTablePrintAndExport
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<AnyObject>({
      startTime: dayjs().subtract(1, 'months').toDate(),
      endTime: dayjs().add(1, 'days').toDate(),
    })
    
    const tableData = ref<AnyArray>([])
    const selectedPan = ref(TabPane.ALL)
    const badgeValues = reactive<any>({})
    const selectCount = ref(0)
    const gxAreaList = reactive<AnyArray>([])
    const exportRef = ref()
    const shippingDetailRef = ref()
    
    const getTableData = async () => {
      const resp = await getOrderList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = resp.data.data.records
      const resultData: AnyArray = []

      const buildGoodsColumns = (orderObj: AnyObject, goodsInfo: AnyObject) => {
        orderObj.goodsName = goodsInfo.categoryName + goodsInfo.goodsName
        const countMode = goodsInfo.countMode
        if (countMode === 1) {
          orderObj.goodsAmount = goodsInfo.volume
          orderObj.goodsUnit = '体积(立方)'
        }
        if (countMode === 2) {
          orderObj.goodsAmount = goodsInfo.weight
          orderObj.goodsUnit = '重量(斤)'
        }
        if (countMode === 3) {
          orderObj.goodsAmount = goodsInfo.quantity
          orderObj.goodsUnit = '数量'
        }
        orderObj.unitPrice = goodsInfo.unitPrice
        orderObj.goodsTotalPrice = goodsInfo.totalPrice
        orderObj.unitDesc = goodsInfo.unitDesc
        return orderObj
      }

      for (let i = 0; i < respData.length; i++) {
        const td = respData[i];
        const goodsList = td.goodsList || []
        td.index = pagination.pageOffset + i
        if (goodsList.length === 0) resultData.push(td)
        for (let j = 0; j < goodsList.length; j++) {
          const goods = goodsList[j];
          if (j === 0) {
            resultData.push(buildGoodsColumns(td, goods))
          } else {
            resultData.push(buildGoodsColumns({orderCode: td.orderCode}, goods))
          }
        }
        if(td.goodsFeeReturnStatus == 2){
          td.payStatus = 0 //货款已退回
        }
        if(td.serviceFeeReturnStatus == 2){
          td.serviceFeePayStatus = 0 //服务费已退回
        }
      }
	  
      tableData.value = []
      nextTick(() => tableData.value = resultData)
      pagination.total = resp.data.data.total
    }

    const spanMethod: SpanMethod = ({ row, column, rowIndex, columnIndex }) => {
      // 需要合并的列
      const columnsToSetRowSpan = [0, 1, 2, 3, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
      if (columnsToSetRowSpan.indexOf(columnIndex) >= 0) {
        if (rowIndex === 0 || row.orderCode !== tableData.value[rowIndex - 1].orderCode) {
          // 如果这行的orderCode和上一行的不一样，则需要合并行
          const rowSpanNumber = tableData.value.filter(d => d.orderCode === row.orderCode).length
          return {
            rowspan: rowSpanNumber,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1
      }
    }

    pagination.setCallback(getTableData)

    onMounted(() => {
      //getTableData() 注释，否则加载页面时执行两次
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
    })

    watch(selectedPan, val => {
      const clearStatus = () => {
        searchData.status1 = undefined
        searchData.status2 = undefined
        searchData.status3 = undefined
        searchData.checkStatus = undefined
        searchData.payStatus = undefined
      }
      clearStatus()
      if (val === TabPane.ALL) {
        // do nothing
      } else if (val === TabPane.UNLOAD) {
        searchData.status1 = 1
        searchData.status2 = 1
      } else if (val === TabPane.LOADED) {
        searchData.status1 = 2
        searchData.status2 = 1
        searchData.status3 = 1
      } else if (val === TabPane.DRIVING) {
        searchData.status1 = 2
        searchData.status2 = 1
        searchData.status3 = 2
      } else if (val === TabPane.UNCHECK) {
        searchData.status1 = 2
        searchData.status2 = 1
        searchData.status3 = 3
      } else if (val === TabPane.CHECKED) {
        searchData.status1 = 2
        searchData.status2 = 2
        searchData.checkStatus = 2
      } else {
        searchData.payStatus = 2
      }
      tableData.value = []
      getTableData()
    }, { immediate: true })

    const searchInputs = reactive<Inputs>([
      {
        label: '货单号',
        type: 'input',
        placeholder: '货单号',
        model: toRef(searchData, 'orderNo'),
        width: '200px',
        clearable: true
      }, {
        label: '胶合板厂',
        type: 'input',
        placeholder: '请输入胶合板厂',
        model: toRef(searchData, 'jhbcName'),
        width: '150px',
        clearable: true
      }, {
        label: '生产机主',
        type: 'input',
        placeholder: '请输入生产机主',
        model: toRef(searchData, 'xqjzName'),
        width: '150px',
        clearable: true
      }, {
        label: '仓管验收状态',
        type: 'select',
        placeholder: '合格状态',
        model: toRef(searchData, 'checkStatus'),
        width: '120px',
        options: [
          { label: '不合格', value: 1 },
          { label: '合格', value: 2 }
        ]
      }, {
        label: '预警状态',
        type: 'select',
        placeholder: '预警状态',
        model: toRef(searchData, 'warnStatus'),
        width: '120px',
        options: [
          { label: '未审核', value: 0 },
          { label: '合格', value: 1 },
          { label: '不合格', value: 2 }
        ]
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        width: '120px',
        model: toRef(searchData, 'areaCode')
      }, {
        label: '时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'startTime'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'endTime'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '是否到账期',
        type: 'select',
        placeholder: '是否到账期',
        clearable: true,
        width: '120px',
        model: toRef(searchData, 'isPaymentDaysExpired'),
        options: [
          { value: 1, label: '是' },
          { value: 2, label: '否' },
        ]
      }, {
        label: '货款支付状态',
        type: 'select',
        placeholder: '请选择货款支付状态',
        clearable: true,
        width: '160px',
        model: toRef(searchData, 'payStatus'),
        options: [
          { value: 1, label: '未支付' },
          { value: 2, label: '已支付' },
          { value: 3, label: '支付失败' },
        ]
      }, {
        label: '货款是否退回',
        type: 'select',
        placeholder: '请选择',
        clearable: true,
        width: '120px',
        model: toRef(searchData, 'isGoodsFeeReturned'),
        options: [
          { value: 1, label: '已退回' },
          { value: 2, label: '未退回' }
        ]
      }, {
        label: '服务费是否退回',
        type: 'select',
        placeholder: '请选择',
        clearable: true,
        width: '120px',
        model: toRef(searchData, 'isServiceFeeReturned'),
        options: [
          { value: 1, label: '已退回' },
          { value: 2, label: '未退回' }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => exportRef.value.doExport()
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        minWidth: '70px',
        align: 'center'
      }, {
        prop: 'orderCode',
        label: '货单号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'jhbcName',
        label: '胶合板厂',
        minWidth: '200px'
      }, {
        prop: 'xqjzName',
        label: '生产机主',
        minWidth: '200px'
      }, {
        prop: 'goodsName',
        label: '货物名称',
        minWidth: '150px'
      }, {
        prop: 'unitPrice',
        label: '货物单价',
        minWidth: '100px'
      }, {
        prop: 'goodsAmount',
        label: '货物数量',
        minWidth: '100px'
      }, {
        prop: 'goodsUnit',
        label: '货物单位',
        minWidth: '120px'
      }, {
        prop: 'goodsTotalPrice',
        label: '货物总价(元)',
        minWidth: '130px'
      }, {
        prop: 'unitDesc',
        label: '货物描述',
        minWidth: '150px'
      }, {
        prop: 'paymentdays',
        label: '账期（天）',
        minWidth: '150px'
      }, {
        prop: 'totalprice',
        label: '订单总价(元)',
        minWidth: '150px'
      }, {
        type: 'render',
        prop: 'loadtme',
        label: '装车时间',
        minWidth: '180px',
        render: scope => formatTime(scope.row.loadtime)
      }, {
        type: 'render',
        prop: 'unloadtime',
        label: '卸车时间',
        minWidth: '180px',
        render: scope => formatTime(scope.row.unloadtime)
      }, {
        type: 'status',
        prop: 'payStatus',
        label: '货款支付状态',
        minWidth: '120px',
        align: 'center',
        statusType: [
          { value: 0, label: '已退回', type: 'info' },
          { value: 1, label: '未支付', type: 'warning' },
          { value: 2, label: '已支付', type: 'success' },
          { value: 3, label: '支付失败', type: 'danger' }
        ]
      }, {
        type: 'status',
        prop: 'serviceFeePayStatus',
        label: '服务费支付状态',
        minWidth: '140px',
        align: 'center',
        statusType: [
          { value: 0, label: '已退回', type: 'info' },
          { value: 1, label: '未支付', type: 'warning' },
          { value: 2, label: '已支付', type: 'success' }
        ]
      }, {
        type: 'status',
        prop: 'checkstatus',
        label: '仓管验收状态',
        minWidth: '120px',
        align: 'center',
        statusType: [
          { label: '不合格', value: 1, type: 'danger' },
          { label: '合格', value: 2, type: 'success' }
        ]
      }, {
        type: 'status',
        prop: 'warnstatus',
        label: '货单预警状态',
        minWidth: '120px',
        align: 'center',
        statusType: [
          { value: 0, type: 'info', label: '未审核' },
          { value: 1, type: 'success', label: '合格' },
          { value: 2, type: 'danger', label: '不合格' },
        ]
      }, {
        prop: 'warndesc',
        label: '预警信息',
        minWidth: '200px',
      }, {
        type: 'button',
        label: '操作',
        // show: () => selectedPan.value === TabPane.CHECKED,
        fixed: 'right',
        buttons: [
          {
            label: () => selectedPan.value === TabPane.CHECKED? '审核': '详情',
            onClick: scope => shippingDetailRef.value.show(scope.row, selectedPan.value)
          }, 
        ]
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['goodsAmount', 'goodsTotalPrice', 'totalprice']
      return getTableColumnsSummary(props, scope.columns, scope.data)
    }
    
    return {
      getTableData,
      formatTime,
      formatDate,
      spanMethod,
      exportRef,
      searchInputs,
      selectedPan,
      searchData,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      shippingDetailRef,
      selectCount,
      mySummaryMethod
    }
  }
})
