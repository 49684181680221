
import { defineComponent, onMounted, ref, watch } from "vue"
import { GEOPosition } from '@/types/Common'
import { getGpsTrack, getStartAndEndPoint, getWxTrack } from '@/api/track'
import '@/utils/TrackAnimation.min'

export default defineComponent({
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    // 轨迹类型 0:全部 1:gps轨迹 2:小程序轨迹
    const trackType = ref(0)
    const startAndEndPoint = ref<AnyObject>({})
    const errMsg = ref('')

    const gpsTrackPoints = ref<AnyArray>([])
    const wxTrackPoints = ref<AnyArray>([])
    const gpsPL = ref<AnyArray>([])
    const wxPL = ref<AnyArray>([]) 

    const fetchTrackPoints = async () => {
      startAndEndPoint.value = {}
      const startAndEndPointResp = await getStartAndEndPoint(props.orderId)
      startAndEndPoint.value = startAndEndPointResp.data.data

      await Promise.all([fetchGPSTrackPoints(), fetchWxTrackPoints()])
    }

    // 获取小程序坐标点
    const fetchWxTrackPoints = async () => {
      wxTrackPoints.value = []
      wxPL.value = []
      const resp = await getWxTrack(props.orderId)
      const respData = resp.data
      if (respData.code != 0) {
        errMsg.value = resp.data.msg as string
        return
      }

      for (let trackPoint of respData.data) {
        wxTrackPoints.value.push({ lon: trackPoint.longitude, lat: trackPoint.latitude })
      }
      wxPL.value = new BMapGL.Polyline(wxTrackPoints.value.map(tp => new BMapGL.Point(Number(tp.lon), Number(tp.lat))), { strokeColor:"blue" })
    }

    // 获取gps坐标点
    const fetchGPSTrackPoints = async () => {
      gpsTrackPoints.value = []
      gpsPL.value = []
      const resp = await getGpsTrack(props.orderId)
      const respData = resp.data
      if (respData.code != 0) {
        errMsg.value = resp.data.msg as string
        return
      }

      for (let trackPoint of respData.data) {
        gpsTrackPoints.value.push({ lon: trackPoint.lon, lat: trackPoint.lat })
      }
      gpsPL.value = new BMapGL.Polyline(gpsTrackPoints.value.map(tp => new BMapGL.Point(Number(tp.lon), Number(tp.lat))), { strokeColor:"green" })
    }

    const getViewPort = (map: any) => {

      const points: AnyArray = []
  
      if (startAndEndPoint.value.startPoint) {
        const point = startAndEndPoint.value.startPoint
        points.push({ lng: point.lon, lat: point.lat })
      }
      if (startAndEndPoint.value.endPoint) {
        const point = startAndEndPoint.value.endPoint
        points.push({ lng: point.lon, lat: point.lat })
      }

      if (gpsTrackPoints.value[0]) {
        let gpsStartPoint = gpsTrackPoints.value[0]
        points.push({ lng: gpsStartPoint.lon, lat: gpsStartPoint.lat })
      }

      if (gpsTrackPoints.value[gpsTrackPoints.value.length - 1]) {
        let gpsEndPoint = gpsTrackPoints.value[gpsTrackPoints.value.length - 1]
        points.push({ lng: gpsEndPoint.lon, lat: gpsEndPoint.lat })
      }

      return map.getViewport(points.map(p => new BMapGL.Point(p.lng, p.lat)))
    }

    const renderMap = () => {
      const bmap = new BMapGL.Map('page-container')

      bmap.enableScrollWheelZoom(true);

      if (startAndEndPoint.value.startPoint) {
        const startPoint = startAndEndPoint.value.startPoint
        const imgUrl = '/static/img/track/起点.png'
        const myIcon = new BMapGL.Icon(imgUrl, new BMapGL.Size(35, 35))

        const startMaker = new BMapGL.Marker(new BMapGL.Point(startPoint.lon, startPoint.lat), {icon: myIcon})
        bmap.addOverlay(startMaker)
      }
      if (startAndEndPoint.value.endPoint) {
        const endPoint = startAndEndPoint.value.endPoint
        const imgUrl = '/static/img/track/终点.png'
        const myIcon = new BMapGL.Icon(imgUrl, new BMapGL.Size(35, 35))

        const endMaker = new BMapGL.Marker(new BMapGL.Point(endPoint.lon, endPoint.lat), {icon: myIcon})
        bmap.addOverlay(endMaker)
      }

      let interval = setInterval(() => {
        const vp = getViewPort(bmap)
        if (!vp.zoom) return
        clearInterval(interval)
        bmap.centerAndZoom(vp.center, vp.zoom);

        if ([0, 1].includes(trackType.value)) {
          bmap.addOverlay(gpsPL.value)
        } 
        if ([0, 2].includes(trackType.value)){
          bmap.addOverlay(wxPL.value)
        }
      }, 100)
    }

    watch(() => props.orderId, async val => {
      trackType.value = 0
      await fetchTrackPoints()
       if (gpsTrackPoints.value.length > 0) {
        renderMap()
      }
    }, { immediate: true })

    watch(trackType, async val => {
      if (gpsTrackPoints.value.length > 0) {
        renderMap()
      }
    }, { immediate: true })

    return {
      errMsg,
      trackType,
      gpsTrackPoints
    }
  }
})
