
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import TrackMap from '@/views/shipping/TrackMap.vue'
import { Buttons, FormItem, FormItems, SelectOption, UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, reactive, ref, toRef } from "vue";
import { getOrderImages, getOrderTrackValidateResult, updateOrderStatus, returnOrderGSFee, OrderPayReturnData, getPayReturnDetail } from '@/api/order'
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { RootState } from "@/types/Store";
import { clearObject, copyObject, formatTime } from "@/utils/common";
import { FileType, fileTypeNameMap, SourceType } from "@/types/Common";
import { BindFileInfo, getFileBasePreviewUrl, UpFileListItem, uploadFileGetFileInfo } from "@/api/file";
import { getJhbImages } from "@/api/jhbc";

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicInputGroup,
    BasicImageList,
    TrackMap
  },
  emits: ['on-success'],
  setup(props, { emit }) {

    const modalShow = ref(false)
    const formData = reactive<AnyObject>({})
    const tFormData = reactive<AnyObject>({})

    const activeTab = ref('1')
    const parentActiveTabPane = ref('')

    const store = useStore<RootState>()
    const trackMapRef = ref()

    const fileList = reactive<Partial<UploadFileExtend>[]>([])
    const orderId = ref(0)

    
    const show = async (detailData: AnyObject, activeTabPane: string) => {
      editMode.value = 1
      orderId.value = detailData.orderid
      parentActiveTabPane.value = activeTabPane
      remarkDialogContent.value = detailData.backRemark
      
      // 获取订单详情
      // const resp = await getOrderDetail(oid)
      // const data = resp.data.data
      modalShow.value = true
      copyObject(formData, detailData)

      // 获取图片baseUrl
      const baseUrlResp = await getFileBasePreviewUrl()
      const baseImgUrl = baseUrlResp.data.data
      // 获取货单对应读图片
      const imgResp = await getOrderImages(detailData.orderid)
      const respFileList = imgResp.data.data as AnyArray

      // 获取订单轨迹校验结果
      const tResp = await getOrderTrackValidateResult(detailData.orderid)
      const tData = tResp.data.data
      if (tData) {
        copyObject(tFormData, tData)
      } else {
        clearObject(tFormData)
      }

      fileList.length = 0
      respFileList.forEach((fileInfo, index) => {
        const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
        fileList.push({ url, title: fileTypeNameMap[fileInfo.filetypeid as FileType] })
      })
    }

    const remarkDialogVisible = ref(false)
    // const remarkDialogTitle = ref('')
    // 1退回货款 2退回服务费
    const payReturnType = ref(1)
    // 编辑模式 1新增 2编辑
    const editMode = ref(1)
    const remarkDialogContent = ref('')
    const returnAmount = ref(0)
    const payReturnFiles = ref<AnyArray>([])
    const payReturnDetail: AnyObject = {}
    const remarkDialogButtons = reactive<Buttons>([
      {
        type: 'default',
        label: '取消',
        onClick: async () => {
          remarkDialogVisible.value = false
        }
      }, {
        type: 'primary',
        label: '确定',
        disabled: () => editMode.value == 2,
        onClick: async () => {
          if(!remarkDialogContent.value){
            return !ElMessage.error("请填写备注")
          }
          if(!returnAmount.value || returnAmount.value <= 0){
            return !ElMessage.error("请填写退款金额")
          }
          const requestData: OrderPayReturnData = {
            orderId: orderId.value,
            amount: returnAmount.value,
            remark: remarkDialogContent.value,
            files: uploadFileInfos.map(f => {
              return {
                fileGuid: f.userFileGuid,
                fileId: f.userFileId,
                fileName: f.fileName
              }
            })
          }

          await returnOrderGSFee(requestData, payReturnType.value)
          ElMessage.success('操作成功')

          if (payReturnType.value == 1) {
            formData.goodsFeeReturnStatus = 2
          } else if (payReturnType.value == 2) {
            formData.serviceFeeReturnStatus = 2
          }
          editMode.value = 2
          return
        }
      }
    ])
    const loadImage = async () => {
      const baseUrlResp = await getFileBasePreviewUrl()
      const baseImgUrl = baseUrlResp.data.data

      // 获取图片
      const respFileList = payReturnDetail.files as AnyArray

      payReturnFiles.value.length = 0
      respFileList.forEach((fileInfo, index) => {
        const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
        payReturnFiles.value.push({ url, id: fileInfo.id })
      })
    }
    const uploadFileInfos: UpFileListItem[] = []
    const onUpload = (type: number) => async (file: UploadFileExtend) => {
      // let sourceTypeId = SourceType.ORDER_PAY_RETURN
      // let fileTypeId
      // // type: 1退回货款 2退回服务费
      // if (type === 1) {
      //   fileTypeId = FileType.ORDER_GOODS_FEE_PAY_RETURN
      // } else {
      //   fileTypeId = FileType.ORDER_SERVICE_FEE_PAY_RETURN
      // }

      const resp = await uploadFileGetFileInfo(file.raw)
      uploadFileInfos.push(resp)
      
      // await bindUploadedFile({
      //   sourceId: payReturnDetail.id,
      //   sourceTypeId,
      //   fileTypeId,
      //   fileGuid: resp.userFileGuid,
      //   fileId: resp.userFileId,
      // })
      loadImage()
    }
    const onFileDelete = (file: AnyObject) => {
      return new Promise<void>((resolve, reject) => {
        const idx = uploadFileInfos.findIndex(f => (file.name as string).startsWith(f.fileName))

        console.log(idx);
        uploadFileInfos.splice(idx, 1)

        console.log(uploadFileInfos);
        // deleteBindUploadedFile([file.id])
        resolve()
      })
    }

    const footerButtons = reactive<Buttons>([
      {
        type: 'primary',
        plain: true,
        label: () => formData.goodsFeeReturnStatus == 1 ? '退回货款' : '查看货款退回凭证',
        onClick: async () => {
          remarkDialogVisible.value = true
          payReturnType.value = 1
          uploadFileInfos.length = 0

          if (formData.goodsFeeReturnStatus != 1) {
            const payReturnDetailResp = await getPayReturnDetail(orderId.value, 1)
            copyObject(payReturnDetail, payReturnDetailResp.data.data)
            editMode.value = 2

            remarkDialogContent.value = payReturnDetail.remark
            returnAmount.value = payReturnDetail.amount
            loadImage()
          } else {
            remarkDialogContent.value = ''
            editMode.value = 1
            returnAmount.value = formData.totalprice
            payReturnFiles.value.length = 0
          }
        }
      }, {
        type: 'primary',
        plain: true,
        label: () => formData.serviceFeeReturnStatus == 1 ? '退回服务费' : '查看服务费退回凭证',
        onClick: async () => {
          remarkDialogVisible.value = true
          payReturnType.value = 2
          uploadFileInfos.length = 0

          if (formData.serviceFeeReturnStatus != 1) {
            const payReturnDetailResp = await getPayReturnDetail(orderId.value, 2)
            copyObject(payReturnDetail, payReturnDetailResp.data.data)
            editMode.value = 2

            remarkDialogContent.value = payReturnDetail.remark
            returnAmount.value = payReturnDetail.amount
            loadImage()
          } else {
            remarkDialogContent.value = ''
            editMode.value = 1
            returnAmount.value = formData.serviceFee
            payReturnFiles.value.length = 0
          }
        }
      }, {
        type: 'primary',
        label: '合格',
        // show: () => parentActiveTabPane.value === TabPane.CHECKED,
        onClick: async () => {
          let desc = formData.warnDesc
          if (!desc) {
            desc = store.state.user.name + ' ' + formatTime(new Date()) + ' 审核通过'
          }
          const result = await updateOrderStatus(formData.orderid, 1, desc)
          ElMessage.success?.('修改成功')
          modalShow.value = false
          emit('on-success')
          // result.data.data
        }
      }, {
        type: 'danger',
        label: '不合格',
        // show: () => parentActiveTabPane.value === TabPane.CHECKED,
        onClick: async () => {
          let desc = formData.warnDesc
          if (!desc) {
            desc = store.state.user.name + ' ' + formatTime(new Date()) + ' 审核不通过'
          }
          const result = await updateOrderStatus(formData.orderid, 2, desc)
          ElMessage.success?.('修改成功')
          modalShow.value = false
          emit('on-success')
        }
      }, {
        type: 'default',
        label: '关闭',
        onClick: () => {
          emit('on-success')
          modalShow.value = false
        },
      }
    ])

    const passStatusOptions: SelectOption[] = [
      { label: '未验收', value: null },
      { label: '不合格', value: 1 },
      { label: '合格', value: 2 },
    ]

    const warnStatusOptions: SelectOption[] = [
      { label: '未审核', value: 0 },
      { label: '合格', value: 1 },
      { label: '不合格', value: 2 }
    ]

    return {
      orderId,
      formData,
      fileList,
      activeTab,
      FileType,
      modalShow,
      footerButtons,
      trackMapRef,
      passStatusOptions,
      warnStatusOptions,
      tFormData,
      show,
      remarkDialogVisible,
      remarkDialogButtons,
      payReturnType,
      remarkDialogContent,
      payReturnFiles,
      editMode,
      returnAmount,
      onFileDelete,
      onUpload
    }
  }
})
